import * as React from "react"
import styled from "styled-components"
import {breakpoint} from "styled-components-breakpoint"

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const PageHeader = styled.div`
    text-align:center;
    font-size: 38px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #363537;
    margin: 50px 0px;
`

const ChildsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    width: 100%;
`

const Child = styled.div`
    ${breakpoint("mobile") `
    width: 100%;
    `}
    ${breakpoint("tablet") `
    width: 400px;
    `}
    font-family: "Roboto", sans-serif;
    color: #363537;
    text-align: center;
    margin: 40px 40px;

    display: flex;
    flex-direction: column;
`

const ChildName = styled.span`
    font-weight: bold;
    font-size: 32px;
`

const ChildDesc = styled.span`
    font-size: 24px;
`

const ConvertDate = (date) =>{
    let conv = date.split("-");
    return conv[2]+"."+conv[1]+"."+conv[0]+"r."
}

const MemoryBookSheet = (props) => {
    let childs = [];
    let key=0;
    let input = props.childs;
    for(const item of input){
        childs.push(
        <Child key={++key}>
            <ChildName>{item.name}</ChildName>
            <ChildDesc>{item.description}</ChildDesc>
        </Child>
        )
    }

  return (
    <PageContainer>
        <PageHeader>W dniu {ConvertDate(props.date)} powierzyliśmy Miłosierdziu Bożemu Dzieci Utracone:</PageHeader>
        <ChildsContainer>
            {childs}
        </ChildsContainer>
    </PageContainer>
  )
}

export default MemoryBookSheet;
