import * as React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import {graphql} from "gatsby";
import Pagination from '@material-ui/lab/Pagination';

import MemoryBookSheet from "./../components/memoryBookPage";

import SEO from './../components/SEO';

export const getMemoryPage = graphql`
query GetMemoryPage {
    allDatoCmsMemorybookpage {
      nodes {
        childs {
          name
          description
        }
        date
      }
      totalCount
    }
  }
`

const MemoryBookContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const MemoryBookHeader = styled.h1`
    font-family: 'Quicksand', sans-serif;
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: #363537;
    position: relative;

    display: flex;
    flex-direction: column;
`

const WidthAnimation = keyframes`
    from{
        width: 0;
    }
    to{
        width: 50%;
    }
`

const HeaderDecoration = styled.div`
    background: #FF7171;
    height: 4px;
    border-radius: 2px;
    width: 50%;
    align-self: center;
    margin-top: 5px;

    animation: ${WidthAnimation} .8s ease-in-out;
`

const PaginationContainer = styled.div`
  width: fit-content;
  align-self: center;

  margin: 15px 0px;
`



const MemoryBookPage = ({data}) => {
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <MemoryBookContainer>

      <SEO title="Miejsce Pamięci Dzieci Utraconych w Dębicy - księga pamięci" link="https://debica-dzieciutracone.pl/ksiega-pamieci" ogTitle="Księga Pamięci - Miejsce Pamięci Dzieci Utraconych w Dębicy" description="Wiele dzieci utraconych ma swoje imię, niektóre zaś piękne i czułe określenia nadane przez rodziców. Tu jest miejsce, by świat je poznał."/>

        <MemoryBookHeader>
            Księga pamięci
            <HeaderDecoration/>
        </MemoryBookHeader>
        <PaginationContainer><Pagination count={data.allDatoCmsMemorybookpage.totalCount} page={page} onChange={handleChange}/></PaginationContainer>
        <MemoryBookSheet date={data.allDatoCmsMemorybookpage.nodes[page-1].date} childs={data.allDatoCmsMemorybookpage.nodes[page-1].childs}/>
    </MemoryBookContainer>
  )
}

export default MemoryBookPage;